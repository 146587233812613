import React, { FunctionComponent } from "react"
import EnquireForm from "../components/forms/EnquireForm"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import EnquireSection from "../components/sections/EnquireSection"

interface EnquireProps {
  title: string
  name: string
  content: string
}

const Enquire: FunctionComponent<EnquireProps> = props => {
  const { title = "Enquire", name = "Keywords", content = "Education" } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <EnquireSection />
      <EnquireForm />
    </Layout>
  )
}

export default Enquire
