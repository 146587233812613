import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1 } from "../../styles/TextStyles"

interface EnquireSectionProps {}

const EnquireSection: FunctionComponent<EnquireSectionProps> = () => {
  return (
    <Wrapper>
      <Title>Get in Touch</Title>
    </Wrapper>
  )
}

export default EnquireSection

const Wrapper = styled.div`
  padding: 200px 38px 0 0;
`
const Title = styled(H1)`
  font-weight: bold;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 30px;
  }
`
