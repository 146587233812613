import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"

interface EnquireFormProps {}

const options = [
  {
    name: "Maritime Training & Education",
  },
  {
    name: "Visa Application Services",
  },
  {
    name: "Company Registration Services",
  },
  {
    name: "Accomodation Services",
  },
  {
    name: "Tourism Services",
  },
]

const fedbacks = [
  {
    name: "Web Search",
  },
  {
    name: "Personal Referral",
  },
  {
    name: "Existing Joshua Organisation Customer",
  },
  {
    name: "Other",
  },
]

const EnquireForm: FunctionComponent<EnquireFormProps> = () => {
  const [formState, setFormState] = useState({
    firstname: "",
    surname: "",
    email: "",
    serviceType: "",
    fedback: "",
    message: "",
  })

  function handleChange(e: any) {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Wrapper>
      <Form
        name="enquire"
        method="post"
        data-netlify="true"
        onSubmit="submit"
        action="/success/"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="enquire" />
        <Division>
          <label htmlFor="name"></label>
          <Input
            required
            id="firstname"
            type="text"
            name="firstname"
            onChange={handleChange}
            value={formState.firstname}
            placeholder="First Name"
          />
        </Division>
        <Division>
          <label htmlFor="surname"></label>
          <Input
            required
            id="surname"
            type="text"
            name="surname"
            onChange={handleChange}
            value={formState.surname}
            placeholder="Surname"
          />
        </Division>
        <Division>
          <label htmlFor="email"></label>
          <Input
            required
            id="email"
            type="email"
            name="email"
            onChange={handleChange}
            value={formState.email}
            placeholder="Email address"
          />
        </Division>
        <Division>
          <label htmlFor="serviceType"></label>
          <Select
            required
            id="serviceType"
            name="serviceType"
            onChange={handleChange}
            value={formState.serviceType}
          >
            <option>Service type</option>
            {options.map((option, index) => (
              <option key={index} value={option.name}>
                {option.name}
              </option>
            ))}
          </Select>
        </Division>
        <Division>
          <label htmlFor="fedback"></label>
          <Select
            required
            id="fedback"
            name="fedback"
            onChange={handleChange}
            value={formState.fedback}
          >
            <option>How did you hear about us</option>
            {fedbacks.map((fed, index) => (
              <option key={index} value={fed.name}>
                {fed.name}
              </option>
            ))}
          </Select>
        </Division>
        <Division>
          <label htmlFor="message"></label>
          <TextArea
            required
            id="message"
            name="message"
            onChange={handleChange}
            value={formState.message}
            placeholder=" Message"
          />
        </Division>
        <Division>
          <Button type="submit">GET IN TOUCH</Button>
        </Division>
      </Form>
    </Wrapper>
  )
}

export default EnquireForm

const Division = styled.div`
  display: grid;
  gap: 30px;
`
const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 20px 38px;
`
const Form = styled.form``
const Input = styled.input`
  width: 500px;
  height: 60px;
  font-size: 14px;
  @media (max-width: 768px) {
    width: 400px;
    height: 30px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 30px;
  }
`
const Select = styled.select`
  width: 505px;
  height: 60px;
  font-size: 14px;
  @media (max-width: 768px) {
    width: 405px;
    height: 30px;
  }
  @media (max-width: 640px) {
    width: 305px;
    height: 30px;
  }
`
const TextArea = styled.textarea`
  width: 500px;
  height: 100px;
  font-size: 14px;
  @media (max-width: 768px) {
    width: 400px;
    height: 30px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 30px;
  }
`
const Button = styled.button`
  margin-top: 40px;
  background: blue;
  width: 220px;
  height: 80px;
  color: white;
  border: solid 1px black;
  font-size: 18px;
  cursor: pointer;
  /* border: none; */
  border-radius: 10px;
`
